import React, { FC } from 'react'
import _ from 'lodash'
import { Layout } from 'antd'
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import FirstAPICall from '../Dashboard/GettingStartedDocumentation/FirstAPICall'
import ProtectedRoute from '../shared/components/ProtectedRoute'
import { useAuth } from '../shared/contexts/authContext'
import ErrorBoundary from '../shared/errorBoundary'
import NotFoundPage from '../shared/pages/NotFoundPage'
import AuthenticationAPIDocumentation from './APIDocumentation/AuthenticationAPIDocumentation'
import AuthenticationAPIDocumentationV2 from './APIDocumentation/AuthenticationAPIDocumentationV2'
import BuildingAPIDocumentation from './APIDocumentation/BuildingAPIDocumentation'
import ElevatorCallAPIDocumentation, {
  elevatorCallApi,
  serviceRobotApi,
} from './APIDocumentation/ElevatorCallAPIDocumentation'
import ElevatorCallAPIv2Documentation, {
  elevatorCallApiV2,
  serviceRobotApiV2,
} from './APIDocumentation/ElevatorCallAPIv2Documentation'
import EquipmentStatusAPIDocumentation from './APIDocumentation/EquipmentStatusAPIDocumentation'
import IntroductionAPI from './APIDocumentation/IntroductionAPIDocumentation'
import SolutionCreationAPI from './APIDocumentation/SolutionCreationAPIDocumentation'
import ServiceInfoAPIDocumentation from './APIDocumentation/ServiceInfoAPIDocumentation'
import ServiceInfoAPIV2Documentation from './APIDocumentation/ServiceInfoAPIV2Documentation'
import ServiceInfoAPIWebhookDocumentation from './APIDocumentation/ServiceInfoAPIWebhookDocumentation'
import Buildings from './Buildings'
import DashboardHome from './DashboardHome'
import Feedback from './Feedback'
import EventsDocumentation from './GettingStartedDocumentation/EventsDocumentation'
import Glossary from './GettingStartedDocumentation/Glossary'
import ProductionAccess from './GettingStartedDocumentation/ProductionAccess'
import Sandbox from './GettingStartedDocumentation/Sandbox'
import ElevatorCallAPIPage from './KONEAPIs/ElevatorCallAPIPage'
import EquipmentStatusAPIPage from './KONEAPIs/EquipmentStatusAPIPage'
import ServiceInfoAPIPage from './KONEAPIs/ServiceInfoAPIPage'
import ServiceRobotAPIPage from './KONEAPIs/ServiceRobotAPIPage'
import MyProfile from './MyProfile'
import OrganizationDetails from './OrganizationDetails'
import Organizations from './Organizations'
import Paths from './Paths'
import SelectOrganization from './SelectOrganization'
import SidebarMenu from './SidebarMenu'
import Statistics from './Statistics'
import Users from './Users'
import Equipments from './Equipments'
import DeviceMappings from './DeviceMappings'
import { ContentWrapper, StyledSider } from './Styles'
import EquipmentStatusAPI2Page from './KONEAPIs/EquipmentStatusAPI2Page'
import EquipmentStatusApi2RestDocumentation from './APIDocumentation/EquipmentStatus2RestAPIDocumentation'
import EquipmentStatusAPIWebhookEventsDocumentation from './APIDocumentation/EquipmentStatusAPIWebhookEventsDocumentation'
import WebhookManagementAPIDocumentation from './APIDocumentation/WebhookManagementAPIDocumentation'
import WebhookCallbackAPIDocumentation from './APIDocumentation/WebhookCallbackAPIDocumentation'
import { ReleaseNotesDocumentation } from './APIDocumentation/ReleaseNotesDocumentation'
import SalesPage from './Salespage'
import AddNewPartner from './Salespage/AddNewParnter'
import PartnerDetails from './Salespage/ParnterDetails'
import PartnerProvider from './Salespage/AddNewParnter/state'
import StatDashboard from './Statistics/StatDashboard'
import OrgProductsDetails from './Statistics/Details/OrgProductsDetails'
import EquipmentLocation from './Statistics/Details/EquipmentLocation'

const Dashboard: FC = () => {
  const { currentUser } = useAuth()
  const match = useRouteMatch()
  const location = useLocation()

  const getExactRoute = () => {
    if (currentUser?.isKoneAdmin) {
      return <Redirect to={Paths.Organizations.path} />
    } else {
      return (
        <ProtectedRoute path={Paths.Dashboard.path}>
          <DashboardHome />
        </ProtectedRoute>
      )
    }
  }

  //some routes might want to modify the content padding by themselves
  const noPaddingContentRoutes = [
    Paths.ElevatorWebsocketAPI.path,
    Paths.ElevatorWebsocketAPIV2.path,
    Paths.ElevatorWebsocketAPIRobots.path,
    Paths.ElevatorWebsocketAPIRobotsV2.path,
    Paths.AuthenticationAPI.path,
    Paths.AuthenticationAPIV2.path,
    Paths.EquipmentStatusAPIDocs.path,
    Paths.ServiceInfoAPIDocs.path,
    Paths.ServiceInfoAPIV2Docs.path,
    Paths.BuildingAPI.path,
    Paths.Glossary.path,
    Paths.WebhookManagementAPIDocs.path,
    Paths.WebhookCallbackAPIDocs.path,
    Paths.AvailabilityAPI.path,
    Paths.ElevatorStatusAPI.path,
    Paths.EntrapmentAPI.path,
    Paths.EquipmentStatus2WebhookEvents.path,
    Paths.EquipmentStatus2WebhookDocs.path,
    Paths.EquipmentStatus2RestAPIDocs.path,
    Paths.EquipmentStatus2RestApiPageDocs.path,
    Paths.EquipmentStatusAPI2Product.path,
  ]

  return (
    <Layout>
      <StyledSider>
        <SelectOrganization />
        <SidebarMenu />
      </StyledSider>

      <ContentWrapper $noPadding={_.includes(noPaddingContentRoutes, location.pathname)}>
        <ErrorBoundary>
          <Switch>
            {currentUser?.isKoneAdmin && (
              <ProtectedRoute path={Paths.Organizations.path}>
                <Organizations />
              </ProtectedRoute>
            )}

            {currentUser?.isKoneAdmin && (
              <ProtectedRoute path={Paths.Buildings.path}>
                <Buildings />
              </ProtectedRoute>
            )}

            <ProtectedRoute exact path={Paths.ElevatorCallAPI.path}>
              <ElevatorCallAPIPage />
            </ProtectedRoute>

            {currentUser?.isKoneAdmin && (
              <ProtectedRoute path={Paths.Organizations.path}>
                <Organizations />
              </ProtectedRoute>
            )}

            {currentUser?.isKoneAdmin && (
              <ProtectedRoute path={Paths.Buildings.path}>
                <Buildings />
              </ProtectedRoute>
            )}

            {currentUser?.isKoneAdmin && (
              <ProtectedRoute path={Paths.Users.path}>
                <Users />
              </ProtectedRoute>
            )}

            {currentUser?.isKoneAdmin && (
              <ProtectedRoute path={Paths.Partners.path}>
                <SalesPage />
              </ProtectedRoute>
            )}
            {currentUser?.isKoneAdmin && (
              <ProtectedRoute path={Paths.AddPartner.path}>
                <PartnerProvider>
                  <AddNewPartner />
                </PartnerProvider>
              </ProtectedRoute>
            )}
            {currentUser?.isKoneAdmin && (
              <ProtectedRoute path={Paths.StatDashboard.path}>
                <PartnerProvider>
                  <StatDashboard />
                </PartnerProvider>
              </ProtectedRoute>
            )}
            {currentUser?.isKoneAdmin && (
              <ProtectedRoute path={Paths.OrgProductsDetails.path}>
                <PartnerProvider>
                  <OrgProductsDetails />
                </PartnerProvider>
              </ProtectedRoute>
            )}
            {currentUser?.isKoneAdmin && (
              <ProtectedRoute path={Paths.ResourceLocationDetails.path}>
                <PartnerProvider>
                  <EquipmentLocation />
                </PartnerProvider>
              </ProtectedRoute>
            )}
            {currentUser?.isKoneAdmin && (
              <ProtectedRoute path={Paths.PartnerDetails.path}>
                <PartnerProvider>
                  <PartnerDetails />
                </PartnerProvider>
              </ProtectedRoute>
            )}
            {currentUser?.isKoneAdmin && (
              <ProtectedRoute path={Paths.Equipments.path}>
                <Equipments />
              </ProtectedRoute>
            )}

            {currentUser?.isKoneAdmin && (
              <ProtectedRoute path={Paths.DeviceMappings.path}>
                <DeviceMappings />
              </ProtectedRoute>
            )}

            {currentUser?.isKoneAdmin && (
              <ProtectedRoute path={Paths.Statistics.path}>
                <Statistics />
              </ProtectedRoute>
            )}

            {/**
             * KONE APIs
             */}

            <ProtectedRoute exact path={Paths.ElevatorCallAPI.path}>
              <ElevatorCallAPIPage />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.ServiceRobotAPI.path}>
              <ServiceRobotAPIPage />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.EquipmentStatusAPIProduct.path}>
              <EquipmentStatusAPIPage />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.EquipmentStatusAPI2Product.path}>
              <EquipmentStatusAPI2Page />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.ServiceInfoAPIProduct.path}>
              <ServiceInfoAPIPage />
            </ProtectedRoute>

            {/**
             * Developer guide
             */}

            <ProtectedRoute exact path={Paths.IntroductionAPI.path}>
              <IntroductionAPI />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.SolutionCreation.path}>
              <SolutionCreationAPI />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.MyFirstAPICall.path}>
              <FirstAPICall />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.SandboxApplication.path}>
              <Sandbox />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.Events.path}>
              <EventsDocumentation />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.Glossary.path}>
              <Glossary />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.ProductionAccess.path}>
              <ProductionAccess />
            </ProtectedRoute>

            {/**
             * API documentation
             * */}

            <ProtectedRoute exact path={Paths.AuthenticationAPI.path}>
              <AuthenticationAPIDocumentation />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.AuthenticationAPIV2.path}>
              <AuthenticationAPIDocumentationV2 />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.BuildingAPI.path}>
              <BuildingAPIDocumentation />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.ElevatorWebsocketAPIRobots.path}>
              <ElevatorCallAPIDocumentation asyncApiProps={serviceRobotApi} />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.ElevatorWebsocketAPI.path}>
              <ElevatorCallAPIDocumentation asyncApiProps={elevatorCallApi} />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.ElevatorWebsocketAPIV2.path}>
              <ElevatorCallAPIv2Documentation asyncApiProps={elevatorCallApiV2} />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.ElevatorWebsocketAPIRobotsV2.path}>
              <ElevatorCallAPIv2Documentation asyncApiProps={serviceRobotApiV2} />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.EquipmentStatusAPIDocs.path}>
              <EquipmentStatusAPIDocumentation />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.EquipmentStatus2RestAPIDocs.path}>
              <EquipmentStatusApi2RestDocumentation />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.EquipmentStatus2WebhookEvents.path}>
              <EquipmentStatusAPIWebhookEventsDocumentation />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.ServiceInfoAPIDocs.path}>
              <ServiceInfoAPIDocumentation />
            </ProtectedRoute>

            {/* <ProtectedRoute exact path={Paths.ServiceInfoAPIV2Docs.path}>
              <ServiceInfoAPIV2Documentation />
            </ProtectedRoute> */}

            <ProtectedRoute exact path={Paths.ServiceInfoAPIV2Docs.path}>
              <ServiceInfoAPIV2Documentation />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.ServiceInfoWebhookAPIDocs.path}>
              <ServiceInfoAPIWebhookDocumentation />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.WebhookManagementAPIDocs.path}>
              <WebhookManagementAPIDocumentation />
            </ProtectedRoute>
            <ProtectedRoute exact path={Paths.WebhookCallbackAPIDocs.path}>
              <WebhookCallbackAPIDocumentation />
            </ProtectedRoute>

            {/**
             * Change logs
             */}

            <ProtectedRoute exact path={Paths.ReleaseNotes.path}>
              <ReleaseNotesDocumentation />
            </ProtectedRoute>

            {/**
             * Customer tools
             */}

            <ProtectedRoute path={Paths.OrganizationDetails.path}>
              <OrganizationDetails />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.Feedback.path}>
              <Feedback />
            </ProtectedRoute>

            <ProtectedRoute exact path={Paths.MyProfile.path}>
              <MyProfile />
            </ProtectedRoute>

            {/**
             * Legacy paths
             */}

            <ProtectedRoute path={Paths.Applications.path}>
              <DashboardHome />
            </ProtectedRoute>

            <ProtectedRoute path={Paths.MyInvitations.path}>
              <MyProfile />
            </ProtectedRoute>

            {/**
             * Exact route
             */}

            {match.isExact && getExactRoute()}

            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </ErrorBoundary>
      </ContentWrapper>
    </Layout>
  )
}

export default Dashboard
