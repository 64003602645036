export const PathNames = {
  dashboard: 'Dashboard',
  public: ' Public',
  applications: 'Applications',
  organizationDetails: 'Organization details',
  koneAPIs: 'KONE APIs',
  elevatorCallAPI: 'Elevator Call API',
  elevatorCallAPIV2: 'Elevator Call API 2',
  serviceRobotAPI: 'Service Robot API',
  serviceRobotAPIV2: 'Service Robot API 2',
  equipmentStatusAPIProduct: 'Equipment Status API',
  equipmentStatusAPI2Product: 'Equipment Status API 2',
  serviceInfoAPIProduct: 'Service Info API',
  serviceInfoAPIV2Product: 'Service Info API 2',
  realtimeMonitoringAPIProduct: 'Real-time Monitoring API',
  elevatorCallAPIDeprecated: 'Elevator Call API - Deprecated',
  serviceRobotAPIDeprecated: 'Service Robot API - Deprecated',
  equipmentStatusAPIProductDeprecated: 'Equipment Status API - Deprecated',
  serviceInfoAPIProductDeprecated: 'Service Info API - Deprecated',

  releaseNotes: 'Release Notes',
  developerGuide: 'Developer guide',
  overviewAPI: 'APIs overview',
  solutionCreation: 'Solution Creation',
  myFirstAPICall: 'Get started with APIs',
  sandboxApplication: 'Access sandbox',
  events: 'Events',
  productionAccess: 'Access KONE data',
  glossary: 'Glossary',

  apiDocumentation: 'API documentation',
  authenticationAPI: 'Authentication API',
  authenticationAPIV2: 'Authentication API 2',
  buildingAPI: 'Building API',
  elevatorWebsocketAPIV2: 'Elevator WebSocket API 2',
  elevatorWebsocketAPI: 'Elevator WebSocket API',
  elevatorWebsocketAPIRobots: 'Elevator WebSocket API',
  elevatorWebsocketAPIRobotsV2: 'Elevator WebSocket API 2',
  equipmentStatusAPIDocs: 'Equipment Status API',
  serviceInfoAPIDocs: 'Service Info API',
  serviceInfoAPIV2Docs: 'Service Info API 2',
  webhooks: 'Webhooks',
  webhookEvents: 'Events',
  webhookManagmementAPIDocs: 'Management API',
  webhookManagmementAPIDocsForKoneAPI: 'Equipment Status 2 API - Webhooks',
  deprecated: 'Deprecated APIs',
  webhookCallbackAPIDocs: 'Callback API',
  availabilityAPI: 'Availability API',
  availabilityRESTAPI: 'Availability REST API',
  availabilityEvents: 'Availability events',
  elevatorStatusAPI: 'Elevator Status API',
  elevatorStatusRESTAPI: 'Elevator Status REST API',
  elevatorStatusEvents: 'Elevator Status events',
  entrapmentAPI: 'Entrapment API',

  myInvitations: 'My invitations',
  feedback: 'Feedback',
  organizations: 'Organizations',
  buildings: 'Buildings',
  users: 'Users',
  partners: 'Integrated Solution',
  addPartner: 'Add New Partner',
  linkIntegrator: 'Link Integrator',
  partnerDetails: 'Partner Details',
  integratedDetails: 'Integrated Details',
  resources: 'Resources',
  equipments: 'Equipment',
  deviceMappings: 'Device Mappings',
  statistics: 'Statistics',
  statDashboard: 'Stat Dashboard',
  orgProductsDetails: 'Product Details',
  resourceLocationDetails: 'Equipment Location Details',

  myProfile: 'My profile',
  myNotifications: 'Notifications',
}
