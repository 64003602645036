import React, { useState } from 'react'
import { StatisticsAPI } from '../../../shared/api/statistics'
import PieChart from '../Graph/RingCharts'
import { H4 } from '../../../shared/components'
import { GraphData, orgTypeLegend, productLegend } from '../../../shared/strings/StatisticDashboard'
import {
  FormLabel,
  TypeSelector,
  Header,
  Item,
  SaveButton,
  ErrorMessage,
  TypeSelectorContainer,
  StyledLine,
  GraphContainer,
  Details,
  GraphHeader,
  Desc,
} from './Styles'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import BarChart from '../Graph/BarGraphs/OrgProduct'
import CountryWiseProducts from '../Graph/BarGraphs/CountryWiseProducts'

const { Option } = TypeSelector

const StatHeader: React.FC = () => {
  const orgData = useQuery(StatisticsAPI.queryNames.GET_ORGANIZATIONS, () => StatisticsAPI.getOrganizations())
  const productCount = useQuery(StatisticsAPI.queryNames.GET_PRODUCTS, () => StatisticsAPI.getProducts())
  const product = useQuery(StatisticsAPI.queryNames.GET_ORGANIZATIONS_AND_PRODUCTS, () =>
    StatisticsAPI.getOrgProducts()
  )
  const countryWiseProducts = useQuery(StatisticsAPI.queryNames.GET_COUNTRY_WISE_PRODUCTS, () =>
    StatisticsAPI.getCountryWiseProduts()
  )
  const [selPrimaryFilter, setSelPrimaryFilter] = useState<string[]>([])
  const [secondaryFilter, setSecondaryFilter] = useState<string[]>([])
  const [orgError, setOrgError] = useState('')
  const [selected, setSeleted] = useState<boolean>(false)
  const history = useHistory()

  const filteredOrgData = {
    sandbox: orgData?.data?.organizations?.sandbox,
    internal: orgData?.data?.organizations?.internal,
    external: orgData?.data?.organizations?.external,
  }

  const filteredProductData = {
    callgiving: productCount?.data?.products?.callgiving,
    robotcall: productCount?.data?.products?.robotcall,
    rtm: productCount?.data?.products?.rtm,
    serviceinfo: productCount?.data?.products?.serviceinfo,
  }

  const filterOption = (input: any, option: any) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    }
    return false
  }

  const handleTypeSelectorChange = (value: string[]) => {
    setSelPrimaryFilter(value)
    setSecondaryFilter([]) // Reset secondary filter when primary filter changes
  }

  const handleProdSelectorChange = (value: string[]) => {
    setSecondaryFilter(value)
  }

  const handleFilterClick = async () => {
    if (selPrimaryFilter.length === 0) {
      setOrgError('Please Select Filter')
    } else {
      setOrgError('')
      setSeleted(true)
    }
  }

  const viewDetails = () => {
    history.push('/dashboard/products-details')
  }

  const viewResourceDetails = () => {
    history.push('/dashboard/resource-location-details')
  }

  const renderSecondaryOptions = () => {
    if (selPrimaryFilter.includes('Organization')) {
      return (
        <Option id="selector-product-option-js" value="api-products">
          {GraphData.apiProduct}
        </Option>
      )
    } else if (selPrimaryFilter.includes('Product')) {
      return (
        <Option id="selector-country-option-js" value="country">
          {GraphData.country}
        </Option>
      )
    }
    return null
  }

  return (
    <>
      <Header>
        <Item style={{ display: 'flex', alignItems: 'flex-start', gap: '20px' }}>
          <div style={{ flex: 1 }}>
            <FormLabel>{GraphData.primaryFilter}</FormLabel>
            <TypeSelectorContainer>
              <TypeSelector
                id="Organization-selector-js"
                showArrow={true}
                value={selPrimaryFilter}
                onChange={(value) => handleTypeSelectorChange(value as string[])}
                getPopupContainer={(trigger) => trigger.parentNode}
                optionFilterProp="children"
                filterOption={filterOption}
              >
                <Option id="selector-Orgnization-option-js" value="Organization">
                  {GraphData.organization}
                </Option>
                <Option id="selector-Product-option-js" value="Product">
                  {GraphData.apiProduct}
                </Option>
              </TypeSelector>
              {orgError && <ErrorMessage>{orgError}</ErrorMessage>}
            </TypeSelectorContainer>
          </div>
          <div style={{ flex: 1 }}>
            <FormLabel>{GraphData.secondaryFilter}</FormLabel>
            <TypeSelector
              id="prod-selector-js"
              showArrow={true}
              mode="multiple"
              value={secondaryFilter}
              onChange={(value) => handleProdSelectorChange(value as string[])}
              getPopupContainer={(trigger) => trigger.parentNode}
              optionFilterProp="children"
              filterOption={filterOption}
            >
              {renderSecondaryOptions()}
            </TypeSelector>
          </div>

          <SaveButton id="save-org-button-js" type="primary" htmlType="submit" onClick={handleFilterClick}>
            {GraphData.search}
          </SaveButton>
        </Item>
      </Header>

      {/* Display the Organization ring chart if only the primary filter is selected. 
      Otherwise, show the bar graph when both the primary and secondary filters are selected. */}

      {selected &&
        selPrimaryFilter.length > 0 &&
        selPrimaryFilter.includes('Organization') &&
        (!secondaryFilter.length ? (
          <>
            <div style={{ marginTop: '20px' }}>
              <StyledLine />
              <H4>{GraphData.ringChartTitle}</H4>
              <Desc>
                {' '}
                <p dangerouslySetInnerHTML={{ __html: GraphData.orgRingChart }}></p>
              </Desc>

              <PieChart data={filteredOrgData} objmap={orgTypeLegend} />
            </div>
          </>
        ) : (
          <>
            <StyledLine />
            <H4>{GraphData.graphTitle}</H4>
            <Desc>
              <p dangerouslySetInnerHTML={{ __html: GraphData.orgProductDec }}></p>
            </Desc>
            <GraphContainer>
              <GraphHeader>
                <H4>{GraphData.orgProductTitle}</H4>
                <Details id="view-details-button-js" type="primary" htmlType="submit" onClick={viewDetails}>
                  {GraphData.viewDetails}
                </Details>
              </GraphHeader>
              <BarChart
                selectedOrgs={GraphData.selectedOrgType}
                selectedProducts={GraphData.apiProductType}
                product={product.data}
              />
            </GraphContainer>
          </>
        ))}

      {/* Display the API Products ring chart if only the primary filter is selected. 
      Otherwise, show the bar graph when both the primary and secondary filters are selected. */}

      {selected &&
        selPrimaryFilter.length > 0 &&
        selPrimaryFilter.includes('Product') &&
        (!secondaryFilter.length ? (
          <>
            <div style={{ marginTop: '20px' }}>
              <div>
                <StyledLine />
                <H4>{GraphData.productCountTitle}</H4>
                <Desc>
                  <p dangerouslySetInnerHTML={{ __html: GraphData.productRingChart }}></p>
                </Desc>
                <PieChart data={filteredProductData} objmap={productLegend} />
              </div>
            </div>
          </>
        ) : (
          <>
            <StyledLine />
            <H4>{GraphData.resourceLocationWiseProduct}</H4>
            <Desc>
              <p dangerouslySetInnerHTML={{ __html: GraphData.countryProductDec }}></p>
            </Desc>
            <GraphContainer>
              <GraphHeader>
                <H4>{GraphData.resourceLocationWiseProduct}</H4>
                <Details id="view-details-button-js" type="primary" htmlType="submit" onClick={viewResourceDetails}>
                  {GraphData.viewDetails}
                </Details>
              </GraphHeader>
              <CountryWiseProducts selectedProducts={GraphData.apiProductType} data={countryWiseProducts.data} />
            </GraphContainer>
          </>
        ))}
    </>
  )
}

export default StatHeader
