import { API } from './API'
import { BackendTokenAPI } from './backend'

export const STATISTICS_PATH = '/admin/statistics'
export const STATISTICS_DASHBOARD_PATH = '/api/admin/statistics'

const ADMIN_SCOPE = ['admin/common']

const queryNames = {
  GET_KPI: 'kpi',
  GET_ORGANIZATIONS_AND_USERS: 'organizations-with-users',
  GET_ORGANIZATIONS: 'organizations',
  GET_ORGANIZATIONS_AND_PRODUCTS: 'organizations?filter=product',
  GET_ORG_AND_PRODUCTS_DETAILS: 'details?primaryFilter=organization&secondaryFilter=product',
  GET_RESOURCE_LOCATION_WISE_DETAILS: 'details?primaryFilter=product&secondaryFilter=country',
  GET_PRODUCTS: 'products',
  GET_COUNTRY_WISE_PRODUCTS: 'products?filter=country',
}

const getKpi = async (): Promise<any> => {
  return await API.get(`${STATISTICS_PATH}/kpi`)
}

const getOrgsWithUsers = async (withEmail: boolean): Promise<any> => {
  return await API.get(`${STATISTICS_PATH}/organizations-with-users`, {
    queryStringParameters: {
      includeEmail: withEmail ? 'true' : 'false',
    },
  })
}

const getOrganizations = async (): Promise<any> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(`${STATISTICS_DASHBOARD_PATH}/organizations`, {
    method: 'GET',
    headers: authHeader,
  })
  if (!response.ok) {
    throw new Error(`Failed fetching organization data: ${response.body ?? response.status}`)
  }
  return (await response.json()) as any
}

const getOrgProducts = async (): Promise<any> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(`${STATISTICS_DASHBOARD_PATH}/organizations?filter=product`, {
    method: 'GET',
    headers: authHeader,
  })
  if (!response.ok) {
    throw new Error(`Failed fetching Organization Count data: ${response.body ?? response.status}`)
  }
  return (await response.json()) as any
}

const getOrgProductDetails = async (): Promise<any> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(
    `${STATISTICS_DASHBOARD_PATH}/details?primaryFilter=organization&secondaryFilter=product`,
    {
      method: 'GET',
      headers: authHeader,
    }
  )
  if (!response.ok) {
    throw new Error(`Failed fetching Organization Details data: ${response.body ?? response.status}`)
  }
  return (await response.json()) as any
}

const getProducts = async (): Promise<any> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(`${STATISTICS_DASHBOARD_PATH}/products`, {
    method: 'GET',
    headers: authHeader,
  })
  if (!response.ok) {
    throw new Error(`Failed fetching API Products Count data: ${response.body ?? response.status}`)
  }
  return (await response.json()) as any
}

const getCountryWiseProduts = async (): Promise<any> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(`${STATISTICS_DASHBOARD_PATH}/products?filter=country`, {
    method: 'GET',
    headers: authHeader,
  })
  if (!response.ok) {
    throw new Error(`Failed fetching API Products Country Wise data: ${response.body ?? response.status}`)
  }
  return (await response.json()) as any
}

const getResourceLocationWiseDetails = async (): Promise<any> => {
  const authHeader = await BackendTokenAPI.convertFrontTokenToBackendToken(ADMIN_SCOPE)
  const response = await fetch(`${STATISTICS_DASHBOARD_PATH}/details?primaryFilter=product&secondaryFilter=country`, {
    method: 'GET',
    headers: authHeader,
  })
  if (!response.ok) {
    throw new Error(`Failed fetching Resource Location Wise data: ${response.body ?? response.status}`)
  }
  return (await response.json()) as any
}

export const StatisticsAPI = {
  queryNames,
  getKpi,
  getOrgsWithUsers,
  getOrganizations,
  getOrgProducts,
  getOrgProductDetails,
  getProducts,
  getCountryWiseProduts,
  getResourceLocationWiseDetails,
}
