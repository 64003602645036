import React, { FC, ChangeEvent, useState } from 'react'
import { StatisticsAPI } from '../../../../shared/api/statistics'
import { useQuery } from 'react-query'
import { TitleLink } from '../../../../App/Header/Styles'
import countryLookup from 'country-code-lookup'
import {
  SearchCreateRow,
  FilterContainer,
  SearchInput,
  MessageContainer,
  CountHeading,
  OrgRowContent,
  PaginationPlacing,
  ProfileDetails,
} from '../../../DeviceMappings/Styles'
import {
  Export,
  FilterButton,
  FilterIcon,
  OrgRowCountry,
  OrgRowContentMedium,
  Row,
  HeaderRow,
  FilterCheckbox,
  StyleCard,
} from '../OrgProductsDetails/Styles'
import {
  equipmentLocationDetails,
  ApiProductMappings,
  equipmentLocation,
  productLegend,
} from '../../../../shared/strings/StatisticDashboard'
import { ReactComponent as LeftArrow } from '../../../../assets/icons-back-arrow.svg'
import { ReactComponent as SearchIcon } from '../../../../assets/icon-search-find.svg'
import { ReactComponent as ClearIcon } from '../../../../assets/icon-search-clear.svg'
import Pagination from '../../../../shared/components/Pagination'
import { TABLE_CONTENT_LENGTH } from '../../../../shared/constants/common'
import { Checkbox } from 'antd'
import { EquipmentLocationDetails } from '../../../../shared/models/statistics'
import { H4 } from '../../../../shared/components'
import { CSVLink } from 'react-csv'
import _ from 'lodash'

const { Group: CheckboxGroup } = Checkbox

const EquipmentLocation: FC = () => {
  const { isLoading, error, data } = useQuery(StatisticsAPI.queryNames.GET_RESOURCE_LOCATION_WISE_DETAILS, () =>
    StatisticsAPI.getResourceLocationWiseDetails()
  )

  const [partnerFilter, setPartnerFilter] = useState('')
  const [page, setPage] = useState(0)
  const [selectedCountries, setSelectedCountries] = useState<string[]>([])
  const [selectedProducts, setSelectedProducts] = useState<string[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isProductOpen, setIsProductOpen] = useState<boolean>(false)

  const handleCountryClick = (): void => {
    setIsOpen(!isOpen)
  }
  const handleProductClick = (): void => {
    setIsProductOpen(!isProductOpen)
  }

  const handleCheckboxChange = (value: string[]) => {
    onProductChange(value)
  }

  const handleCheckboxCountry = (value: string[]) => {
    onCountryChange(value)
  }

  const filResult: EquipmentLocationDetails[] = []

  console.log('selected products', selectedProducts)
  const showContentRows = () => {
    let filteredResObject
    data.data?.forEach((obj: EquipmentLocationDetails) => {
      filteredResObject = filResult.filter((val) => val.name === obj.name)
      if (filteredResObject.length === 0) {
        filResult.push({
          name: obj.name,
          country: obj.country,
          resource_type: obj.resource_type,
          equipment_id: obj.equipment_id,
          address: obj.address,
          city: obj.city,
          zipcode: obj.zipcode,
        })
      }
    })
  }
  if (data) {
    showContentRows()
  }

  const onCountryChange = (value: string[]) => {
    setPage(0)
    setSelectedCountries(value)
  }

  const onProductChange = (value: string[]) => {
    setPage(0)
    setSelectedProducts(value)
  }

  const getProductsFromVersions = (key: string) => {
    return ApiProductMappings.get(key)
  }

  console.log('checking product data from details', data)

  const getCountries = () => {
    if (!data || !data.data) return []

    const selectedProducts = ['serviceinfo', 'rtm', 'callgiving', 'robotcall']

    // Extract unique countries that match the selectedProducts names
    const uniqueCountries = data.data.reduce((acc: any, curr: any) => {
      if (selectedProducts.includes(curr.name) && curr.country && !acc.includes(curr.country)) {
        acc.push(curr.country)
      }
      return acc
    }, [])

    // Filter out the value "0" from the uniqueCountries array(have other equipment details like zipcode, address but not country)
    const filteredCountries = uniqueCountries.filter((country: any) => country !== '0')

    return filteredCountries.map((country: any) => ({
      label: countryLookup.byIso(country)?.country || country,
      value: country,
    }))
  }

  const checkCountry = (country: string | undefined, selCountries: string[]): boolean => {
    if (!country) return false

    return selCountries.some((ct) => {
      return country === ct.toString().toLowerCase()
    })
  }

  const checkProduct = (productname: string | undefined, selproduct: string[]): boolean => {
    if (!productname) return false

    return selproduct.some((pr) => {
      return productname === pr.toString().toLowerCase()
    })
  }

  const onFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    setPartnerFilter(event.target.value.toLowerCase())
  }

  const orgProductContent = (val: EquipmentLocationDetails) => {
    return (
      <ProfileDetails>
        <Row>
          <OrgRowContent>{getProductsFromVersions(val.name)}</OrgRowContent>
          <OrgRowContent>{val.equipment_id}</OrgRowContent>
          <OrgRowContent>{_.capitalize(val.resource_type)}</OrgRowContent>
          <OrgRowContent> {countryLookup.byIso(val.country ? val.country : 0)?.country}</OrgRowContent>
          <OrgRowContent>{val.city}</OrgRowContent>
          <OrgRowContent>{val.address}</OrgRowContent>
          <OrgRowContent>{val.zipcode}</OrgRowContent>
        </Row>
      </ProfileDetails>
    )
  }

  const showorgProductList = () => {
    if (isLoading) {
      return <MessageContainer>{equipmentLocationDetails.header}</MessageContainer>
    }
    if (error) {
      return <MessageContainer>{equipmentLocationDetails.loadingList}</MessageContainer>
    }

    const filteredData = data?.data
      .filter((val: EquipmentLocationDetails) => {
        if (val.name === 'equipmentstatus' || val.name === 'Owns' || val.resource_type === 'fake') {
          return false
        }
        const productName =
          val.name?.toString().toLowerCase().includes(partnerFilter.toLowerCase()) ||
          getProductsFromVersions(val.name)?.toLowerCase().includes(partnerFilter.toLowerCase())

        const countryNameMatch = countryLookup
          .byIso(val.country ? val.country : 0)
          ?.country.toString()
          .toLowerCase()
          .includes(partnerFilter.toLowerCase())

        const resourceTypeMatch = val.resource_type?.toString().toLowerCase().includes(partnerFilter.toLowerCase())
        const resourceId = val.equipment_id?.toString().toLowerCase().includes(partnerFilter.toLowerCase())
        const addressMatch = val.address?.toString().toLowerCase().includes(partnerFilter.toLowerCase())
        const cityMatch = val.city?.toString().toLowerCase().includes(partnerFilter.toLowerCase())
        const zipcodeMatch = val.zipcode?.toString().toLowerCase().includes(partnerFilter.toLowerCase())

        // Check if any field matches the filter criteria
        return partnerFilter !== undefined
          ? productName ||
              countryNameMatch ||
              resourceTypeMatch ||
              resourceId ||
              addressMatch ||
              cityMatch ||
              zipcodeMatch
          : true
      })
      .filter((val: EquipmentLocationDetails) => {
        // Convert selectedCountries to country names using countryLookup.byIso
        const convertedSelectedCountries = selectedCountries.map((countryCode) => {
          const countryName = countryLookup.byIso(countryCode)?.country
          return countryName ? countryName.toString().toLowerCase() : countryCode.toString().toLowerCase()
        })

        // Filter by selected countries
        return convertedSelectedCountries.length > 0
          ? checkCountry(
              countryLookup
                .byIso(val.country ? val.country : 0)
                ?.country.toString()
                .toLowerCase(),
              convertedSelectedCountries
            )
          : true
      })
      .filter((val: EquipmentLocationDetails) => {
        // Filter by selected products
        return selectedProducts.length > 0 ? checkProduct(val.name?.toString().toLowerCase(), selectedProducts) : true
      })
    console.log('checking values', selectedCountries)

    const infoTable = filteredData.map((val: EquipmentLocationDetails) => orgProductContent(val))

    const csvData = filteredData.map((val: EquipmentLocationDetails) => ({
      name: getProductsFromVersions(val.name),
      equipment_id: val.equipment_id,
      resource_type: _.capitalize(val.resource_type),
      country: countryLookup.byIso(val.country ? val.country : 0)?.country,
      city: val.city,
      address: val.address,
      zipcode: val.zipcode,
    }))

    return (
      <>
        <TitleLink id="go-to-org-product-link-js" to="/dashboard/dashboard-statistics" style={{ width: 'fitcontent' }}>
          {<LeftArrow />}
          <H4>{equipmentLocationDetails.header}</H4>
        </TitleLink>
        <SearchCreateRow>
          <FilterContainer>
            <SearchInput
              id="search-partner-input-js"
              placeholder="Search"
              onChange={onFilterChange}
              suffix={partnerFilter ? <ClearIcon onClick={() => setPartnerFilter('')} /> : <SearchIcon />}
              value={partnerFilter}
            />
          </FilterContainer>
          <Export id="export-button-js">
            <CSVLink data={csvData} headers={equipmentLocation} filename={'Equipment-location-details.csv'}>
              {equipmentLocationDetails.export}
            </CSVLink>
          </Export>
        </SearchCreateRow>
        <CountHeading></CountHeading>
        <HeaderRow>
          <OrgRowContentMedium>
            <OrgRowCountry>
              <FilterButton onClick={handleProductClick}>
                <FilterIcon />
                <OrgRowContentMedium>{equipmentLocationDetails.name}</OrgRowContentMedium>
              </FilterButton>
              {isProductOpen && (
                <StyleCard>
                  <CheckboxGroup
                    style={{ width: '60%', display: 'flex', flexDirection: 'column' }}
                    value={selectedProducts}
                    onChange={(value) => {
                      handleCheckboxChange(value as string[])
                    }}
                  >
                    <FilterCheckbox value="callgiving">{productLegend.callgiving}</FilterCheckbox>
                    <FilterCheckbox value="robotcall">{productLegend.robotcall}</FilterCheckbox>
                    <FilterCheckbox value="rtm">{productLegend.rtm}</FilterCheckbox>
                    <FilterCheckbox value="serviceinfo">{productLegend.serviceinfo}</FilterCheckbox>
                  </CheckboxGroup>
                </StyleCard>
              )}
            </OrgRowCountry>
          </OrgRowContentMedium>
          <OrgRowContentMedium>{equipmentLocationDetails.equipment_id}</OrgRowContentMedium>
          <OrgRowContentMedium>{equipmentLocationDetails.resource_type}</OrgRowContentMedium>
          <OrgRowContentMedium>
            {' '}
            <OrgRowCountry>
              <FilterButton onClick={handleCountryClick}>
                <FilterIcon />
                <OrgRowContentMedium>{equipmentLocationDetails.country}</OrgRowContentMedium>
              </FilterButton>

              {isOpen && (
                <StyleCard>
                  <CheckboxGroup
                    style={{ width: '60%', display: 'flex', flexDirection: 'column' }}
                    value={selectedCountries}
                    onChange={(value) => {
                      handleCheckboxCountry(value as string[])
                    }}
                  >
                    {getCountries().map((country: any) => (
                      <FilterCheckbox key={country.value} value={country.value}>
                        {country.label}
                      </FilterCheckbox>
                    ))}
                  </CheckboxGroup>
                </StyleCard>
              )}
            </OrgRowCountry>
          </OrgRowContentMedium>
          <OrgRowContentMedium>{equipmentLocationDetails.city}</OrgRowContentMedium>
          <OrgRowContentMedium>{equipmentLocationDetails.address}</OrgRowContentMedium>
          <OrgRowContentMedium>{equipmentLocationDetails.zipcode}</OrgRowContentMedium>
        </HeaderRow>
        {infoTable.slice(page * TABLE_CONTENT_LENGTH, TABLE_CONTENT_LENGTH * (page + 1))}
        <PaginationPlacing>
          <Pagination dataLength={infoTable.length == 0 ? 1 : infoTable.length} page={page} setPage={setPage} />
        </PaginationPlacing>
      </>
    )
  }
  return <div>{showorgProductList()}</div>
}

export default EquipmentLocation
