import { PathNames } from '../../shared/strings/PathsContent'

type Page =
  | 'Dashboard'
  | 'Applications'
  | 'OrganizationDetails'
  | 'IntroductionAPI'
  | 'SolutionCreation'
  | 'KoneAPIs'
  | 'DeveloperGuide'
  | 'ReleaseNotes'
  | 'MyFirstAPICall'
  | 'SandboxApplication'
  | 'ProductionAccess'
  | 'AuthenticationAPI'
  | 'AuthenticationAPIV2'
  | 'AuthenticationAPIV2Token'
  | 'Events'
  | 'BuildingAPI'
  | 'ElevatorCallAPI'
  | 'ServiceRobotAPI'
  | 'EquipmentStatusAPIProduct'
  | 'EquipmentStatusAPI2Product'
  | 'ServiceInfoAPIProduct'
  | 'ServiceInfoAPIV2Product'
  | 'Deprecated'
  | 'MyInvitations'
  | 'MyNotifications'
  | 'Feedback'
  | 'Organizations'
  | 'Buildings'
  | 'Users'
  | 'Partners'
  | 'AddPartner'
  | 'LinkIntegrator'
  | 'PartnerDetails'
  | 'Equipments'
  | 'DeviceMappings'
  | 'Resources'
  | 'Statistics'
  | 'StatDashboard'
  | 'OrgProductsDetails'
  | 'ResourceLocationDetails'
  | 'APIDocumentation'
  | 'ElevatorWebsocketAPI'
  | 'ElevatorWebsocketAPIV2'
  | 'ElevatorWebsocketAPIRobots'
  | 'ElevatorWebsocketAPIRobotsV2'
  | 'EquipmentStatusAPIDocs'
  | 'EquipmentStatus2RestAPIDocs'
  | 'EquipmentStatus2RestApiPageDocs'
  | 'EquipmentStatus2WebhookDocs'
  | 'EquipmentStatus2WebhookEvents'
  | 'ServiceInfoAPIDocs'
  | 'ServiceInfoAPIV2Docs'
  | 'ServiceInfoAPIV2ApiPageDocs'
  | 'ServiceInfoWebhookAPIApiPageDocs'
  | 'ServiceInfoWebhookAPIDocs'
  | 'WebhookManagementAPIDocs'
  | 'WebhookCallbackAPIDocs'
  | 'WebhookManagementAPIDocsForKoneAPI'
  | 'AvailabilityAPI'
  | 'AvailabilityRESTAPI'
  | 'AvailabilityEvents'
  | 'ElevatorStatusAPI'
  | 'ElevatorStatusRESTAPI'
  | 'ElevatorStatusEvents'
  | 'EntrapmentAPI'
  | 'Glossary'
  | 'MyProfile'

type PathStructure = { [page in Page]: { path: string; name: string } }

const Paths: PathStructure = {
  Dashboard: { path: '/dashboard', name: PathNames.dashboard },
  OrganizationDetails: { path: '/dashboard/organization-details', name: PathNames.organizationDetails },

  KoneAPIs: { path: '/dashboard/kone-apis', name: PathNames.koneAPIs },
  Resources: { path: '/dashboard/resources', name: PathNames.resources },
  ElevatorCallAPI: { path: '/dashboard/kone-apis/elevator-call-api', name: PathNames.elevatorCallAPI },
  ServiceRobotAPI: { path: '/dashboard/kone-apis/service-robot-api', name: PathNames.serviceRobotAPI },
  ReleaseNotes: { path: '/dashboard/release-notes', name: PathNames.releaseNotes },
  EquipmentStatusAPIProduct: {
    path: '/dashboard/kone-apis/equipment-status-api',
    name: PathNames.equipmentStatusAPIProduct,
  },
  EquipmentStatusAPI2Product: {
    path: '/dashboard/kone-apis/equipment-status-api-2',
    name: PathNames.equipmentStatusAPI2Product,
  },
  ServiceInfoAPIProduct: { path: '/dashboard/kone-apis/service-info-api', name: PathNames.serviceInfoAPIProduct },
  ServiceInfoAPIV2Product: { path: '/dashboard/kone-apis/service-info-api', name: PathNames.serviceInfoAPIV2Product },

  DeveloperGuide: { path: '/dashboard/developer-guide', name: PathNames.developerGuide },
  IntroductionAPI: { path: '/dashboard/developer-guide/overview-api', name: PathNames.overviewAPI },
  SolutionCreation: { path: '/dashboard/developer-guide/solution-creation', name: PathNames.solutionCreation },
  MyFirstAPICall: { path: '/dashboard/developer-guide/first-api-call', name: PathNames.myFirstAPICall },
  SandboxApplication: { path: '/dashboard/developer-guide/sandbox', name: PathNames.sandboxApplication },
  Events: { path: '/dashboard/developer-guide/events', name: PathNames.events },
  ProductionAccess: { path: '/dashboard/developer-guide/production-access', name: PathNames.productionAccess },
  Glossary: { path: '/dashboard/developer-guide/glossary', name: PathNames.glossary },

  APIDocumentation: { path: '/dashboard/api-documentation', name: PathNames.apiDocumentation },
  AuthenticationAPI: { path: '/dashboard/api-documentation/authentication-api', name: PathNames.authenticationAPI },
  AuthenticationAPIV2: {
    path: '/dashboard/api-documentation/authentication-api-v2',
    name: PathNames.authenticationAPIV2,
  },
  AuthenticationAPIV2Token: {
    path: '/dashboard/api-documentation/authentication-api-v2#getAccessToken',
    name: PathNames.authenticationAPIV2,
  },
  BuildingAPI: { path: '/dashboard/api-documentation/building-api', name: PathNames.buildingAPI },
  ElevatorWebsocketAPI: {
    path: '/dashboard/api-documentation/elevator-websocket-api',
    name: PathNames.elevatorWebsocketAPI,
  },
  ElevatorWebsocketAPIV2: {
    path: '/dashboard/api-documentation/elevator-websocket-api-v2',
    name: PathNames.elevatorWebsocketAPIV2,
  },
  ElevatorWebsocketAPIRobots: {
    path: '/dashboard/api-documentation/elevator-websocket-api/robots',
    name: PathNames.elevatorWebsocketAPIRobots,
  },
  ElevatorWebsocketAPIRobotsV2: {
    path: '/dashboard/api-documentation/elevator-websocket-api-v2/robots',
    name: PathNames.elevatorWebsocketAPIRobotsV2,
  },
  EquipmentStatusAPIDocs: {
    path: '/dashboard/api-documentation/equipment-status-api',
    name: PathNames.equipmentStatusAPIDocs,
  },
  EquipmentStatus2RestAPIDocs: {
    path: '/dashboard/api-documentation/equipment-status-api-2/rest',
    name: 'REST API',
  },
  EquipmentStatus2RestApiPageDocs: {
    path: '/dashboard/api-documentation/equipment-status-api-2/rest',
    name: `${PathNames.equipmentStatusAPI2Product} - REST API`,
  },
  EquipmentStatus2WebhookDocs: {
    path: '/dashboard/api-documentation/equipment-status-api-2/webhook',
    name: PathNames.webhooks,
  },
  EquipmentStatus2WebhookEvents: {
    path: '/dashboard/api-documentation/equipment-status-api-2/webhook/events',
    name: PathNames.webhookEvents,
  },
  Deprecated: {
    path: '/dashboard/api-documentation/deprecated',
    name: PathNames.deprecated,
  },
  ServiceInfoAPIDocs: { path: '/dashboard/api-documentation/service-info-api', name: PathNames.serviceInfoAPIDocs },
  ServiceInfoAPIV2Docs: {
    path: '/dashboard/api-documentation/service-info-api-v2/rest',
    name: 'REST API',
  },
  ServiceInfoWebhookAPIDocs: {
    path: '/dashboard/api-documentation/service-info-api-v2/webhook',
    name: ' Webhooks',
  },
  ServiceInfoAPIV2ApiPageDocs: {
    path: '/dashboard/api-documentation/service-info-api-v2/rest',
    name: 'Service Info 2 - REST API',
  },
  ServiceInfoWebhookAPIApiPageDocs: {
    path: '/dashboard/api-documentation/service-info-api-v2/webhook',
    name: 'Service Info 2 - Webhooks',
  },
  WebhookManagementAPIDocs: {
    path: '/dashboard/api-documentation/webhook-management-api',
    name: PathNames.webhookManagmementAPIDocs,
  },
  WebhookCallbackAPIDocs: {
    path: '/dashboard/api-documentation/webhook-callback-api',
    name: PathNames.webhookCallbackAPIDocs,
  },
  WebhookManagementAPIDocsForKoneAPI: {
    path: '/dashboard/api-documentation/webhook-management-api',
    name: PathNames.webhookManagmementAPIDocsForKoneAPI,
  },
  AvailabilityAPI: {
    path: '/dashboard/api-documentation/availability-api',
    name: PathNames.availabilityAPI,
  },
  AvailabilityRESTAPI: {
    path: '/dashboard/api-documentation/availability-api/rest',
    name: PathNames.availabilityRESTAPI,
  },
  AvailabilityEvents: {
    path: '/dashboard/api-documentation/availability-api/events',
    name: PathNames.availabilityEvents,
  },
  ElevatorStatusAPI: {
    path: '/dashboard/api-documentation/elevator-status-api',
    name: PathNames.elevatorStatusAPI,
  },
  ElevatorStatusRESTAPI: {
    path: '/dashboard/api-documentation/elevator-status-api/rest',
    name: PathNames.elevatorStatusRESTAPI,
  },
  ElevatorStatusEvents: {
    path: '/dashboard/api-documentation/elevator-status-api/events',
    name: PathNames.elevatorStatusEvents,
  },
  EntrapmentAPI: {
    path: '/dashboard/api-documentation/entrapment-api',
    name: PathNames.entrapmentAPI,
  },

  Feedback: { path: '/dashboard/feedback', name: PathNames.feedback },
  Organizations: { path: '/dashboard/organizations', name: PathNames.organizations },
  Buildings: { path: '/dashboard/buildings', name: PathNames.buildings },
  Users: { path: '/dashboard/users', name: PathNames.users },
  Partners: { path: '/dashboard/partners', name: PathNames.partners },
  AddPartner: { path: '/dashboard/addPartner', name: PathNames.addPartner },
  LinkIntegrator: { path: '/dashboard/linkIntegrator', name: PathNames.linkIntegrator },
  PartnerDetails: { path: '/dashboard/partner-details/:partnerId', name: PathNames.partnerDetails },
  Equipments: { path: '/dashboard/equipments', name: PathNames.equipments },
  DeviceMappings: { path: '/dashboard/device-mappings', name: PathNames.deviceMappings },
  Statistics: { path: '/dashboard/statistics', name: PathNames.statistics },
  StatDashboard: { path: '/dashboard/dashboard-statistics', name: PathNames.statDashboard },
  OrgProductsDetails: { path: '/dashboard/products-details', name: PathNames.orgProductsDetails },
  ResourceLocationDetails: { path: '/dashboard/resource-location-details', name: PathNames.resourceLocationDetails },

  MyProfile: { path: '/dashboard/my-profile', name: PathNames.myProfile },

  // Legacy paths
  Applications: { path: '/dashboard/applications', name: PathNames.applications },
  MyInvitations: { path: '/dashboard/invitations', name: PathNames.myInvitations },
  MyNotifications: { path: '/dashboard/notifications', name: PathNames.myNotifications },
}

export default Paths
