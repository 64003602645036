import React, { FC, useState, useEffect } from 'react'
import countries from 'country-code-lookup'
import { Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient, useMutation } from 'react-query'
import { ResourcesAPI } from '../../../../shared/api/resources'
import { BuildingsAPI } from '../../../../shared/api/buildings'
import { Button, Modal, Toast, ErrorMessage } from '../../../../shared/components'
import { ResourceObject, ResourceObjectPatch } from '../../../../shared/models/resource'
import {
  EditResourcesStrings,
  ResourceListStrings,
  SharedOrganizationsStrings,
} from '../../../../shared/strings/OrganizationsContent'
import cities from 'all-countries-and-cities-json'
import { GEO_COORDINATE, ZIP_CODE } from '../../../../shared/constants/auth'

import {
  FormContainer,
  FormLabel,
  InputsContainer,
  Line,
  TypeSelector,
  InputColumn,
  InputRowContainer,
  InputName,
  Address,
  InputId,
  TypeSelectorSmall,
  ButtonsContainer,
  CreateButton,
  CancelButton,
  InputCoordinate,
  InputRowContainerCoordinate,
  InputDescription,
  RemoveButton,
  LabelHolder,
} from './Styles'

const { Option } = TypeSelector

type Props = {
  resource: ResourceObject
  organizationId: number
  onRemoveStart: () => void
  onRemoveComplete: () => void
  onCloseEdit: () => void
}

const EditResourcesForm: FC<Props> = ({ resource, organizationId, onRemoveStart, onRemoveComplete, onCloseEdit }) => {
  let address
  if (resource?.resourceInfo?.address) {
    address = JSON.parse(resource?.resourceInfo?.address)
  }
  const [isLoading, setIsLoading] = useState(false)
  const [resourceType, setResourceType] = useState<string>(resource.resourceId.type || '')
  const [resourceUsageType, setResourceUsageType] = useState<string>(resource.resourceInfo?.resourceUsageType || '')
  const [countryCode, setCountryCode] = useState<number>(address?.country || 0)
  const [city, setCity] = useState<string>(address?.city || '')
  const [buildingId, setBuildingId] = useState<any>(resource.resourceId.uniqueId || '')

  const [hasFetchedData, setHasFetchedData] = useState(false)

  useEffect(() => {
    if (!hasFetchedData) {
      const fetchData = async () => {
        if (resourceType === 'group') {
          try {
            // Extracting the part before the colon for group type
            const buildingId = resource.resourceId.uniqueId.split(':')[0]
            const data = await BuildingsAPI.getDeviceMappingByBuildingId(buildingId)
            setBuildingId(data)
            console.log('Successfully fetched device mapping', data)
            setIsLoading(false)
            setHasFetchedData(true) // Set the flag to prevent subsequent API calls
          } catch (error) {
            console.error('Error fetching data:', error)
            setIsLoading(false)
          }
        }
      }

      fetchData()
    }
  }, [resource.resourceId.uniqueId, hasFetchedData])

  // Extract "ken" prefix
  const extractKens = (str: any) => {
    if (str.startsWith('ken')) {
      return str.slice(3)
    } else {
      return ''
    }
  }

  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
    reset,
  } = useForm({
    defaultValues: {
      id: {
        internalId: resource.resourceId.internalId || '',
        type: resource.resourceId.type || '',
        uniqueId: resource.resourceId.uniqueId || '',
      },
      info: {
        resourceUsageType: resource.resourceInfo?.resourceUsageType || '',
        resourceName: resource.resourceInfo?.resourceName || '',
        address: resource.resourceInfo?.address ? JSON.parse(resource.resourceInfo?.address) : '',
        description: resource.resourceInfo?.description || '',
      },
    },
  })
  const [modalVisible, setModalVisible] = useState(false)
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const [isUpdateErrorVisible, setIsUpdateErrorVisible] = useState(false)
  const { mutateAsync: removeResource, isLoading: isRemoving } = useMutation(ResourcesAPI.remove)
  const [isSuccessVisible, setIsSuccessVisible] = useState(false)
  const [apiErrors, setAPIErrors] = useState<string[]>()
  const queryClient = useQueryClient()

  const showModal = () => {
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const onClickRemove = async () => {
    onRemoveStart()
    setIsErrorVisible(false)

    try {
      await removeResource({
        organizationId,
        data: {
          products: resource.products,
          resourceId: { type: resource.resourceId.type, uniqueId: resource.resourceId.uniqueId },
        },
      })
      setModalVisible(false)
      onRemoveComplete()
    } catch (err) {
      setModalVisible(false)
      setIsErrorVisible(true)
    }
  }

  const onTypeChange = (data: any) => {
    setResourceType(data)
  }
  const onUsageTypeChange = (data: any) => {
    setResourceUsageType(data)
  }
  const onCountryChange = (isoNo: any) => {
    setCountryCode(Number(isoNo))
  }
  const onCityChange = (cityName: any) => {
    setCity(cityName)
  }

  const getCountries = () => {
    return countries.countries.map((countryItem) => {
      return (
        <Option
          key={countryItem.isoNo}
          id={countryItem.country.replace('/ ./g', '-') + '.js'}
          value={countryItem.isoNo}
        >
          {countryItem.country}
        </Option>
      )
    })
  }
  const getCountryName = (id: number) => {
    return countries.byIso(id)?.country
  }
  const getCities = () => {
    let country: any = 'Finland'
    country = countryCode ? countries.byIso(countryCode)?.country : 'India'
    if (country in cities) {
      return cities[country].map((city: string) => {
        let i = 0
        return (
          <Option key={city + ++i + Math.random()} id={city.replace('/ ./g', '-') + '.js'} value={city}>
            {city}
          </Option>
        )
      })
    } else {
      return (
        <Option key="not-found" id="not-found" value="Cities Not found">
          Not Found
        </Option>
      )
    }
  }
  const filterOption = (input: any, option: any) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    }
    return false
  }

  const filterOptionCity = (input: any, option: any) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
    }
    return false
  }

  const deleteResource = () => {
    return (
      <>
        <Toast
          isVisible={isErrorVisible}
          text={ResourceListStrings.removeResourceError}
          onDismiss={() => setIsErrorVisible(false)}
          type="error"
        />
        <ListTableContentRow onClickRemove={showModal} isRemoving={isRemoving} />
        <Modal
          title={SharedOrganizationsStrings.pleaseNoteModalTitle}
          width={672}
          visible={modalVisible}
          onOk={onClickRemove}
          confirmLoading={isRemoving}
          onCancel={handleCancel}
          closable={false}
          footer={[
            <Button
              id="remove-resource-button-js"
              size="large"
              style={{ width: '304px', marginRight: '24px' }}
              key="submit"
              ghost={true}
              onClick={onClickRemove}
              loading={isRemoving}
            >
              Yes, remove
            </Button>,
            <Button
              id="cancel-remove-resource-button-js"
              size="large"
              style={{ width: '272px' }}
              key="back"
              type="primary"
              onClick={handleCancel}
            >
              {SharedOrganizationsStrings.goBackButton}
            </Button>,
          ]}
        >
          {`${ResourceListStrings.deleteResourceStart} ${resource.resourceId.type} ${ResourceListStrings.withId} ${resource.resourceId.uniqueId}`}
        </Modal>
      </>
    )
  }
  //submit function
  const onSubmit = async (data: ResourceObjectPatch) => {
    data.id.type = resourceType ?? null
    data.info.resourceUsageType = resourceUsageType ?? null
    data.info.address['country'] = countryCode ?? null
    data.info.address['city'] = city ?? null
    data.info.address = JSON.stringify(data.info.address)

    const errors = []

    try {
      await ResourcesAPI.updateResource(data)
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? EditResourcesStrings.errorOccurred
      errors.push(`${resource}: ${errorMessage}`)
    }
    if (errors.length) {
      setAPIErrors(errors)
      setIsUpdateErrorVisible(true)
    } else {
      setIsSuccessVisible(true)
    }

    await queryClient.invalidateQueries(`${ResourcesAPI.queryNames.GET_BY_ORGANIZATION_ID}${organizationId}`)
  }

  return (
    <>
      <Toast
        isVisible={isSuccessVisible}
        text={EditResourcesStrings.resourceUpdatedSuccess}
        textId="add-resources-success-js"
        onDismiss={() => setIsSuccessVisible(false)}
        type="success"
      />
      <Toast
        isVisible={isUpdateErrorVisible}
        text={EditResourcesStrings.updateResourceError}
        textId="add-resources-toast-error-js"
        onDismiss={() => setIsUpdateErrorVisible(false)}
        type="error"
      />
      <form
        id="resource-edit-form-js"
        onSubmit={(e: any) => {
          e.stopPropagation()
          handleSubmit(onSubmit)(e)
        }}
      >
        <FormContainer>
          <InputsContainer>
            <InputRowContainer>
              <InputColumn>
                <FormLabel>{EditResourcesStrings.resourceType}</FormLabel>
                <TypeSelector
                  id="resource-type-selector-js"
                  defaultValue={resourceType}
                  onChange={onTypeChange}
                  disabled={isLoading}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Option id="selector-building-option-js" value="building">
                    {EditResourcesStrings.buildingOption}
                  </Option>
                  <Option id="selector-ken-option-js" value="ken">
                    {EditResourcesStrings.kenOption}
                  </Option>
                </TypeSelector>
              </InputColumn>
              <InputColumn>
                <FormLabel>Resource ID</FormLabel>
                <div>
                  <Controller
                    render={({ field }) => <InputId id="salesforce-input-js" {...field} disabled />}
                    name="id.uniqueId"
                    control={control}
                  />
                </div>
              </InputColumn>
            </InputRowContainer>
            <InputRowContainer>
              <InputColumn>
                <FormLabel>Resource usage type</FormLabel>
                <TypeSelector
                  id="resource-usage-type-selector-js"
                  defaultValue={
                    resourceUsageType != 'none' && resourceUsageType != null ? resourceUsageType : 'Select usage type'
                  }
                  onChange={onUsageTypeChange}
                  disabled={isLoading}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Option id="selector-building-option-js" value="demo">
                    Demo
                  </Option>
                  <Option id="selector-ken-option-js" value="pilot">
                    Pilot
                  </Option>
                  <Option id="selector-ken-option-js" value="customer">
                    Customer
                  </Option>
                </TypeSelector>
              </InputColumn>
              <InputColumn>
                <FormLabel>Resource Name</FormLabel>
                <div>
                  <Controller
                    render={({ field }) => <InputName id="resource-name-input-js" {...field} />}
                    name="info.resourceName"
                    control={control}
                  />
                </div>
              </InputColumn>
            </InputRowContainer>
            <InputRowContainer>
              <InputColumn></InputColumn>
              <InputColumn>
                {resourceType === 'group' && (
                  <div>
                    <FormLabel>API KEN</FormLabel>
                    <Input value={buildingId?.businessAssetNumbers?.map(extractKens).join(', ') || ''} disabled />
                  </div>
                )}
              </InputColumn>
            </InputRowContainer>
            <Line />
            <InputRowContainer>
              <InputColumn>
                <FormLabel>Address line 1</FormLabel>
                <div>
                  <Controller
                    render={({ field }) => <Address id="address1-input-js" {...field} />}
                    name="info.address.addressLine1"
                    control={control}
                  />
                </div>
              </InputColumn>
              <InputColumn>
                <FormLabel>Address line 2</FormLabel>
                <div>
                  <Controller
                    render={({ field }) => <Address id="address2-input-js" {...field} />}
                    name="info.address.addressLine2"
                    control={control}
                  />
                </div>
              </InputColumn>
            </InputRowContainer>
            <InputRowContainer>
              <InputColumn>
                <FormLabel>Country</FormLabel>
                <TypeSelectorSmall
                  showSearch
                  id="country-selector-js"
                  defaultValue={countryCode ? getCountryName(countryCode) : 'Select Country'}
                  onChange={onCountryChange}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  optionFilterProp="children"
                  filterOption={filterOption}
                >
                  {getCountries()}
                </TypeSelectorSmall>
              </InputColumn>
              <InputColumn>
                <FormLabel>City</FormLabel>
                <TypeSelectorSmall
                  showSearch
                  id="city-selector-js"
                  defaultValue={city ? city : 'Select city'}
                  onChange={onCityChange}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  optionFilterProp="children"
                  filterOption={filterOptionCity}
                  disabled={countryCode ? false : true}
                >
                  {getCities()}
                </TypeSelectorSmall>
              </InputColumn>
              <InputColumn>
                <FormLabel>Zip code</FormLabel>
                <div>
                  <Controller
                    render={({ field }) => <InputId id="zipcode-input-js" {...field} />}
                    name="info.address.zipcode"
                    control={control}
                    defaultValue={address?.zipCode}
                    rules={{
                      pattern: { value: ZIP_CODE, message: 'Incorrect Zip code value' },
                    }}
                  />
                  {errors?.info?.address?.zipcode && (
                    <ErrorMessage>{errors?.info?.address?.zipcode.message}</ErrorMessage>
                  )}
                </div>
              </InputColumn>
            </InputRowContainer>
            <InputRowContainerCoordinate>
              <InputColumn>
                <FormLabel>Lattitude</FormLabel>
                <div>
                  <Controller
                    render={({ field }) => <InputCoordinate id="lattitude-input-js" {...field} />}
                    name="info.address.coordinates.lattitue"
                    control={control}
                    rules={{
                      pattern: { value: GEO_COORDINATE, message: 'Incorrect Geo coordinate' },
                    }}
                  />
                  {errors?.info?.address?.coordinates?.lattitue && (
                    <ErrorMessage>{errors?.info?.address?.coordinates?.lattitue.message}</ErrorMessage>
                  )}
                </div>
              </InputColumn>
              <InputColumn>
                <FormLabel>Longitude</FormLabel>
                <div>
                  <Controller
                    render={({ field }) => <InputCoordinate id="longitude-input-js" {...field} />}
                    name="info.address.coordinates.longitude"
                    control={control}
                    rules={{
                      pattern: { value: GEO_COORDINATE, message: 'Incorrect Geo coordinate' },
                    }}
                  />
                  {errors?.info?.address?.coordinates?.longitude && (
                    <ErrorMessage>{errors?.info?.address?.coordinates?.longitude.message}</ErrorMessage>
                  )}
                </div>
              </InputColumn>
            </InputRowContainerCoordinate>
            <Line />
            <InputRowContainer>
              <InputColumn>
                <FormLabel>General description</FormLabel>
                <div>
                  <Controller
                    render={({ field }) => <InputDescription rows={4} id="description-input-js" {...field} />}
                    name="info.description"
                    control={control}
                  />
                </div>
              </InputColumn>
            </InputRowContainer>
            <Line />
            <InputRowContainer>
              <InputColumn>
                <FormLabel>Date Added</FormLabel>
                <div>
                  {resource?.resourceInfo?.updatedAt
                    ? new Date(resource?.resourceInfo?.updatedAt).toISOString().substring(0, 10)
                    : ''}
                </div>
              </InputColumn>
              <InputColumn>
                <FormLabel>Added by</FormLabel>
                <div>
                  <LabelHolder>{resource?.resourceInfo?.updatedBy ? resource.resourceInfo.updatedBy : ''}</LabelHolder>
                </div>
              </InputColumn>
              <InputColumn>{deleteResource()}</InputColumn>
            </InputRowContainer>
            <InputRowContainer>
              <InputColumn>
                <FormLabel>Linked to organization</FormLabel>
                <div>{resource?.resourceInfo?.linkedOrganizatons ? resource.resourceInfo.linkedOrganizatons : ''}</div>
              </InputColumn>
            </InputRowContainer>
            <Line />
            <ButtonsContainer>
              <CancelButton id="cancel-resource-edit-button-js" onClick={onCloseEdit} disabled={isLoading} ghost>
                CANCEL
              </CancelButton>
              <CreateButton id="edit-resource-button-js" type="primary" htmlType="submit" loading={isLoading}>
                SAVE CHANGES
              </CreateButton>
            </ButtonsContainer>
          </InputsContainer>
        </FormContainer>
      </form>
    </>
  )
}

type tableProps = {
  onClickRemove?: () => void
  isRemoving?: boolean
}

const ListTableContentRow: FC<tableProps> = ({ onClickRemove, isRemoving }) => {
  return (
    <>
      {onClickRemove && (
        <RemoveButton id="remove-item-button-js" onClick={onClickRemove} loading={isRemoving}>
          {SharedOrganizationsStrings.removeResourceButton}
        </RemoveButton>
      )}
    </>
  )
}

export default EditResourcesForm
