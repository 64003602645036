export const KpiNamesGeneral: Map<string, string> = new Map([
  ['kenResources', 'KEN resources'],
  ['buildingResources', 'Building Resources'],
  ['productionApplications', 'Production Applications'],
  ['sandboxApplications', 'Sandbox Applications'],
])

export const KpiNamesAPIExplorer: Map<string, string> = new Map([
  ['personalOrganizations', 'Personal Organizations'],
  ['personalOrganizationsWithSandboxApplications', 'Personal organizations with sandbox applications'],
])

export const KpiNamesAPIConsumer: Map<string, string> = new Map([
  ['externalOrganizations', 'Customer organizations'],
  ['externalOrganizationsWithCallgivingProduct', 'Customer organizations with Elevator Call API'],
  ['externalOrganizationsWithRobotcallProduct', 'Customer organizations with Service Robot API'],
  ['externalOrganizationsWithEquipmentstatusProduct', 'Customer organizations with Equipment Status API'],
  ['externalOrganizationsWithServiceinfoProduct', 'Customer organizations with Service Info API'],
  ['externalOrganizationsWithSandboxApplications', 'Customer organizations with sandbox applications'],
  ['externalOrganizationsWithProductionApplications', 'Customer organizations with production applications'],
  ['externalOrganizationsWithBuildingResources', 'Customer organizations with building resources'],
  ['internalOrganizations', 'Internal organizations'],
  ['internalOrganizationsWithCallgivingProduct', 'Internal organizations with Elevator Call API'],
  ['internalOrganizationsWithEquipmentstatusProduct', 'Internal organizations with Equipment Status API'],
  ['internalOrganizationsWithSandboxApplications', 'Internal oganizations with sandbox applications'],
  ['internalOrganizationsWithProductionApplications', 'Internal organizations with production applications'],
  ['internalOrganizationsWithBuildingResources', 'Internal organizations with building resources'],
])

export type userData = {
  userId: string
  userEmail: string
}

export type orgUsersData = {
  externalId: string
  orgType: string
  orgName: string
  createdAt: string
  numSandboxApps: number
  numProdApps: number
  users: [userData]
}

export type User = {
  firstName: string
  lastName: string
  email: string
  status: string
  invitationId: number
  username: string
  created: string
  updated: string
  appCount?: number
  sandboxCount?: number
  prodCount?: number
  organization?: string[]
  subscriptionType: string[]
  subscribed?: boolean
  enabled: string
  organizationIds?: string[]
}

export type SubscriptionType = {
  type: string
  subscribed: boolean
}

export type UserApp = {
  email: string
  count: number
  sandboxCount?: number
  prodCount?: number
}

export type OrgProductDetails = {
  partnername: string
  country: number
  productname: string
  equipmentscount: string
}

export type EquipmentLocationDetails = {
  name: string
  country: number
  resource_type: string
  equipment_id: string
  address: string
  city: string
  zipcode: number
}
